@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.active svg {
    color: #ff784b;
}

.button-primary-md {
    @apply bg-orange px-8 py-3 text-white font-semibold rounded-md;
}

.button-primary-md:hover {
    @apply bg-orange-active shadow-inner;
}

tbody tr td:first-child {
    padding-left: 2.5rem;
}

tbody tr td:last-child {
    padding-right: 1.5rem;
}

thead tr th:first-child {
    padding-left: 2.5rem;
}
